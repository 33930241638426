import React from 'react';
import Container from '@material-ui/core/Container';

export default function Home() {
  return (
    <Container maxWidth="sm">
      <h1>Home</h1>
    </Container>
  );
}
