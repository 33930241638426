import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './views/Home';
import Minesweeper from './views/Minesweeper';
import RPS from './views/RPS';
import Snake from './views/Snake';
import Sudoku from './views/Sudoku';
import TTT from './views/TTT';
import NavBar from './components/layout/NavBar';
import './App.css';

function App() {
  return (
    <Router>
      <div>
        <NavBar />

        <Switch>
          <Route path="/minesweeper">
            <Minesweeper />
          </Route>
          <Route path="/rock-paper-scissors">
            <RPS />
          </Route>
          <Route path="/snake">
            <Snake />
          </Route>
          <Route path="/sudoku">
            <Sudoku />
          </Route>
          <Route path="/tic-tac-toe">
            <TTT />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
