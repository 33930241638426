import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';

const weapons = {
  rock: {
    wins: 'scissors',
  },
  paper: {
    wins: 'rock',
  },
  scissors: {
    wins: 'paper',
  },
};

const weaponsKeys = Object.keys(weapons);

const getRandomWeapon = () => {
  return weaponsKeys[Math.floor(Math.random() * Math.floor(3))];
};

const getWinner = (weapon1, weapon2) => {
  if (weapon1 === weapon2) return 0;
  return weapons[weapon1].wins === weapon2 ? 1 : 2;
};

const Game = () => {
  const [player, setPlayer] = useState({ weapon: null, score: 0 });
  const [computer, setComputer] = useState({ weapon: null, score: 0 });
  const [loading, setLoading] = useState(false);
  const [winner, setWinner] = useState(null);

  const setResult = () => {
    const victor = getWinner(player.weapon, computer.weapon);

    setPlayer({
      ...player,
      ...(victor === 1 ? { score: player.score + 1 } : {}),
    });

    setComputer({
      ...computer,
      ...(victor === 2 ? { score: computer.score + 1 } : {}),
    });

    switch (victor) {
      case 0:
        setWinner('Draw');
        break;
      case 1:
        setWinner('Player');
        break;
      case 2:
        setWinner('Computer');
        break;
      default:
        setWinner('Draw');
    }

    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setResult();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [loading]);

  const play = (weapon) => {
    const weapon1 = weapon;
    const weapon2 = getRandomWeapon();

    setPlayer({ ...player, weapon: weapon1, loading: true });
    setComputer({ ...computer, weapon: weapon2, loading: true });
    setLoading(true);
  };

  const restart = () => {
    setPlayer({
      ...player,
      weapon: null,
    });
    setComputer({
      ...computer,
      weapon: null,
    });
  };

  const reset = () => {
    setPlayer({ weapon: null, score: 0 });
    setComputer({ weapon: null, score: 0 });
  };

  return (
    <div>
      {!loading && (
        <div>
          <Button variant="contained" color="primary" onClick={() => play('rock')}>
            Rock
          </Button>
          <Button variant="contained" color="primary" onClick={() => play('paper')}>
            Paper
          </Button>
          <Button variant="contained" color="primary" onClick={() => play('scissors')}>
            Scissors
          </Button>
          <Button variant="contained" color="secondary" onClick={() => restart()}>
            Restart
          </Button>
          <Button variant="contained" color="secondary" onClick={() => reset()}>
            Reset
          </Button>
        </div>
      )}

      <p>{winner}</p>
      <p>{player.score}</p>
      <p>{computer.score}</p>
    </div>
  );
};

export default Game;
