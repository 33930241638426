import React from 'react';
import Container from '@material-ui/core/Container';
import Game from '../components/rps/Game';

export default function RPS() {
  return (
    <Container maxWidth="sm">
      <Game />
    </Container>
  );
}
